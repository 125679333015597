import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>©2023 QMotion by QLogic LLC All rights reserved</p>
      </div>
    </footer>
  );
}
